/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/bootstrap@5.2.3/dist/css/bootstrap.min.css
 * - /npm/@fancyapps/ui@5.0.13/dist/fancybox/fancybox.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
